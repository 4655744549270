import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundIconComponent } from 'src/app/components/shared/round-icon/round-icon.component';
import { InquiryElement } from 'src/app/models/inquiry';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { WasteTitle } from 'src/app/pipes/waste-title.pipe';
import { WasteTitleComponent } from 'src/app/components/matchmaking/waste-title/waste-title.component';

@Component({
  selector: 'app-waste-pills',
  standalone: true,
  templateUrl: './waste-pills.component.html',
  styleUrls: ['./waste-pills.component.scss'],
  imports: [
    CommonModule,
    RoundIconComponent,
    AppMaterialModule,
    WasteTitle,
    WasteTitleComponent,
  ],
})
export class WastePillsComponent implements OnChanges {
  @Input() inquiryElements: InquiryElement[];
  @Input() truncateAfterIndex = 2;
  @Input() missing = false;
  showAll = false;
  validInquiryElements: InquiryElement[];

  ngOnChanges() {
    this.validInquiryElements = this.inquiryElements.filter((element) =>
      Boolean(element.aut || element.ewc || element.category)
    );
  }
}
