<ng-container *ngIf="validInquiryElements">
  <ul class="waste-list">
    <li
      *ngFor="let element of validInquiryElements.slice(0, truncateAfterIndex)"
    >
      <app-round-icon class="mr-1 icon"> delete_outline </app-round-icon>
      <span class="waste-list__text">
        <app-waste-title [class.missing]="missing" [element]="element" />
      </span>
    </li>
    <ng-container *ngIf="showAll">
      <li
        *ngFor="let element of validInquiryElements.slice(truncateAfterIndex)"
      >
        <app-round-icon class="mr-1 icon"> delete_outline </app-round-icon>
        <span class="waste-list__text">
          <app-waste-title [class.missing]="missing" [element]="element" />
        </span>
      </li>
    </ng-container>
  </ul>

  <ng-container *ngIf="validInquiryElements.length > truncateAfterIndex">
    <button
      mat-button
      (click)="showAll = true"
      [attr.aria-expanded]="showAll"
      *ngIf="!showAll"
      class="mt-2"
    >
      {{ validInquiryElements.length - truncateAfterIndex }} weitere anzeigen…
    </button>
  </ng-container>
</ng-container>
